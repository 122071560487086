import React from "react";
import { Link } from "gatsby"
import Img from "gatsby-image"
import { useTranslation } from "react-i18next";
import Moment from 'react-moment'
import SocialShare from "../SocialShare"
import InstagramWidget from "../InstagramWidget";
import rehypeReact from 'rehype-react'
import MarkdownImage from '../MarkdownImage'
import './event.scss'


export default ({ data, recentEvents, instaFeed, content }) => {

    const { t } = useTranslation();
    const dateFormat = t('dateFormat')
    const renderAst = new rehypeReact({
        createElement: React.createElement,
        components: { 'markdown-image': MarkdownImage },
    }).Compiler

    return(
        <div id="event-area" className="blog-area pt-100 pb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 blog-post-item order-1 order-lg-2">
                        <div className="blog-wrapper blog-details">
                            <div className="blog-img img-full">
                                <Img sizes={data.image.childImageSharp.fluid} />   
                            </div>
                            <div className="blog-content">                                 
                                <p className="blog-meta">
                                    <Moment format={dateFormat}>
                                    {data.date}
                                    </Moment>
                                </p>
                                <h3>{data.title}</h3>
                                { renderAst(content) }
                            </div>
                        </div>
                        <div class="common-tag-and-next-prev mt-60">
                            <SocialShare title={data.title} hashtags="event,comingsoon"/>
                        </div>
                    </div>
                    <div className="col-lg-4 blog-sidebar order-2 order-lg-1">
                      <div className="widget mb-60">
                          <div className="widget-title">
                              <h4>{t('recent_events')}</h4>
                          </div>
                          <div className="sidebar-rc-post">
                              <ul>
                                  {recentEvents.map(({node: {frontmatter: eventItem}}) => 
                                    eventItem.slug !== data.slug && (
                                    <li>
                                        <div className="rc-post-thumb img-full">
                                            <Link to={eventItem.slug}><img src={eventItem.image.childImageSharp.fluid.src} srcset={eventItem.image.childImageSharp.fluid.srcSet} sizes={eventItem.image.childImageSharp.fluid.sizes} alt="{eventItem.title" /></Link>
                                        </div>
                                        <div className="rc-post-content">
                                            <div className="widget-date">
                                                <Moment format={dateFormat}>
                                                    {eventItem.date}
                                                </Moment>
                                            </div>
                                            <h4><Link to={eventItem.slug}>{eventItem.title}</Link></h4>
                                        </div>
                                    </li>
                                    ))}
                              </ul>
                          </div>
                      </div>
                      <div className="widget mb-60">
                        <InstagramWidget data={instaFeed} />
                      </div>
                    </div>
                </div>
            </div>
        </div>
    )}